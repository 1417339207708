export default {
  "c-exchange-reqItem-cancelling-request":
    "Stornierung Ihrer Austausch-Anfrage",
  "c-exchange-reqItem-cancel-request-confirm":
    "Sind Sie sicher, dass Sie die Anfrage stornieren möchten?",
  "c-exchange-reqItem-confirming-documents": "Bestätigung Ihrer Dokumente",
  "c-exchange-reqItem-upload-documents-instructions":
    "Stellen Sie sicher, dass die Bilder Ihres Dokuments eine hohe Auflösung haben und alle Ziffern deutlich sichtbar sind",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Möchten Sie mit der Dokumentenbestätigung fortfahren?",
  "c-exchange-reqItem-select-payment-option": "Zahlungsoption auswählen",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Möchten Sie mit der ausgewählten Zahlungsoption fortfahren?",
  "c-exchange-reqItem-confirming-payment": "Zahlung bestätigen",
  "c-exchange-reqItem-confirm-payment":
    "Möchten Sie mit der Bestätigung Ihrer Zahlung fortfahren?",
  "c-exchange-reqItem-completing-request": "Anfrage abschließen",
  "c-exchange-reqItem-provide-txid":
    "Um die Anfrage abzuschließen, müssen Sie die Transaktions-ID Ihres Transfers angeben",
  "c-exchange-reqItem-enter-txid-error": "Bitte geben Sie hier txId ein",
  "c-exchange-reqItem-enter-txid-placeholder": "Geben Sie hier txId ein",
  "c-exchange-reqItem-verifying-card": "Überprüfung der Benutzerkarte",
  "c-exchange-reqItem-moderator-verifying": "Moderator überprüft Daten",
  "c-exchange-reqItem-waiting-alm": "Warten auf ALM-Ergebnisse",
  "c-exchange-reqItem-preparing-crypto": "Vorbereitung der Plattformadresse",
  "c-exchange-reqItem-preparing-fiat": "Vorbereitung der Fiat-Angaben",
  "c-exchange-reqItem-sending-crypto": "Senden von Krypto",
  "c-exchange-reqItem-sending-fiat": "Senden von Fiat",
  "c-exchange-reqItem-waiting-payment": "Warten auf die Zahlung des Benutzers",
  "c-exchange-reqItem-completed": "Abgeschlossen",
  "c-exchange-reqItem-failed": "Fehlgeschlagen",
  "c-exchange-reqItem-cancelled": "Abgebrochen",
  "c-exchange-reqItem-cancel-exchange-request": "Austauschanfrage stornieren",
  "c-exchange-reqItem-confirm-documents": "Dokumente bestätigen",
  "c-exchange-reqItem-documents-confirmed": "Dokumente wurden bestätigt",
  "c-exchange-reqItem-payment-sent": "Zahlung wurde gesendet",
  "c-exchange-reqItem-confirm-user-payment": "Zahlung des Benutzers bestätigen",
  "c-exchange-reqItem-complete-request": "Anfrage abschließen",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Austausch",
  "c-exchange-reqItem-crypto-address": "Krypto-Adresse des Benutzers",
  "c-exchange-reqItem-fiat-card-number": "Fiat-Kartennummer des Benutzers",
  "c-exchange-reqItem-request-failed": "Anfrage fehlgeschlagen",
  "c-exchange-reqItem-alm-link": "ALM-Ergebnis-Link",
  "c-exchange-reqItem-check-here": "hier überprüfen",
  "c-exchange-reqItem-request-cancelled": "Anfrage abgebrochen",
  "c-exchange-reqItem-request-fulfilled": "Ihre Anfrage wurde erfüllt!",
  "c-exchange-reqItem-thank-you":
    "Vielen Dank, dass Sie uns als Ihren bevorzugten Austauschdienst gewählt haben. Wir schätzen Ihr Vertrauen in uns und freuen uns darauf, Ihnen wieder zu dienen!",
  "c-exchange-reqItem-address-preparation":
    "Hier wird die Adresse für Ihre Übertragung angezeigt.",
  "c-exchange-reqItem-security-check":
    "Bitte warten Sie, bis alle Vorbereitungen und Sicherheitsüberprüfungen abgeschlossen sind.",
  "c-exchange-reqItem-waiting-for-transaction":
    "Die Plattform wartet auf Ihre Transaktion.",
  "c-exchange-reqItem-transfer-here": "Überweisen Sie die Mittel hierher",
  "c-exchange-reqItem-wallet": "Wallet",
  "c-exchange-reqItem-payment-option": "Zahlungsoption",
  "c-exchange-reqItem-card-number": "Kartennummer",
  "c-exchange-reqItem-description": "Beschreibung",
  "c-exchange-reqItem-balance": "Kontostand",
  "c-exchange-reqItem-payment-instructions":
    "Nachdem Sie die Zahlung getätigt haben, teilen Sie bitte ein Bild der Rechnung (oder einen Screenshot) Ihrer Zahlung im Chat",
  "c-exchange-reqItem-verification-required": "Identitätsprüfung erforderlich",
  "c-exchange-reqItem-upload-photo-instructions":
    "Um Ihre Austausch-Anfrage fortzusetzen, müssen Sie ein Foto von sich hochladen, auf dem Sie die Kreditkarte halten, die Sie verwenden möchten. Stellen Sie bitte sicher, dass:",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "Der Name des Karteninhabers und alle Ziffern der Kartennummer deutlich sichtbar sind.",
  "c-exchange-reqItem-photo-instruction-face":
    "Ihr Gesicht auf dem Foto deutlich sichtbar ist.",
  "c-exchange-reqItem-click-confirm-documents":
    "Nachdem Sie das Foto im Chat hochgeladen haben, klicken Sie oben auf die Schaltfläche 'Dokumente bestätigen' und warten Sie, bis unser Moderator Ihre Informationen überprüft.",
  "c-exchange-reqItem-verification-in-progress": "Verifizierung läuft",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Vielen Dank für das Hochladen Ihres Fotos. Bitte warten Sie, während unser Moderator Ihre Informationen überprüft. Dieser Vorgang kann einige Minuten dauern. Wir danken Ihnen für Ihre Geduld.",
  "c-exchange-reqItem-select-payment-option-description":
    "Wählen Sie eine Zahlungsoption aus der untenstehenden Liste. Die ausgewählte Option wird dem Kunden als Zahlungsmethode für die Übertragung von Geldern angezeigt.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Mit der ausgewählten Option fortfahren",
};
