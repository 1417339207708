export default {
  "c-exchange-reqItem-cancelling-request": "取消您的交换请求",
  "c-exchange-reqItem-cancel-request-confirm": "您确定要取消请求吗？",
  "c-exchange-reqItem-confirming-documents": "确认您的文件",
  "c-exchange-reqItem-upload-documents-instructions":
    "请确保您的文件图像具有高分辨率，且所有数字清晰可见。",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "您是否要继续进行文件确认？",
  "c-exchange-reqItem-select-payment-option": "选择付款选项",
  "c-exchange-reqItem-proceed-with-payment-option":
    "您是否要继续选择的付款选项？",
  "c-exchange-reqItem-confirming-payment": "确认付款",
  "c-exchange-reqItem-confirm-payment": "您是否要继续确认您的付款？",
  "c-exchange-reqItem-completing-request": "完成请求",
  "c-exchange-reqItem-provide-txid": "要完成请求，您需要提供转账的交易ID",
  "c-exchange-reqItem-enter-txid-error": "请在此输入txId",
  "c-exchange-reqItem-enter-txid-placeholder": "在此输入txId",
  "c-exchange-reqItem-verifying-card": "验证用户的卡",
  "c-exchange-reqItem-moderator-verifying": "主持人在验证数据",
  "c-exchange-reqItem-waiting-alm": "等待ALM结果",
  "c-exchange-reqItem-preparing-crypto": "准备平台地址",
  "c-exchange-reqItem-preparing-fiat": "准备法币信息",
  "c-exchange-reqItem-sending-crypto": "发送加密货币",
  "c-exchange-reqItem-sending-fiat": "发送法币",
  "c-exchange-reqItem-waiting-payment": "等待用户付款",
  "c-exchange-reqItem-completed": "已完成",
  "c-exchange-reqItem-failed": "失败",
  "c-exchange-reqItem-cancelled": "已取消",
  "c-exchange-reqItem-cancel-exchange-request": "取消交换请求",
  "c-exchange-reqItem-confirm-documents": "确认文件",
  "c-exchange-reqItem-documents-confirmed": "文件已确认",
  "c-exchange-reqItem-payment-sent": "付款已发送",
  "c-exchange-reqItem-confirm-user-payment": "确认用户付款",
  "c-exchange-reqItem-complete-request": "完成请求",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "交换中",
  "c-exchange-reqItem-crypto-address": "用户的加密货币地址",
  "c-exchange-reqItem-fiat-card-number": "用户的法币卡号",
  "c-exchange-reqItem-request-failed": "请求失败",
  "c-exchange-reqItem-alm-link": "ALM结果链接",
  "c-exchange-reqItem-check-here": "点击这里查看",
  "c-exchange-reqItem-request-cancelled": "请求已取消",
  "c-exchange-reqItem-request-fulfilled": "您的请求已完成！",
  "c-exchange-reqItem-thank-you":
    "感谢您选择我们作为您的首选交换服务。我们非常感谢您的信任，并期待再次为您服务！",
  "c-exchange-reqItem-address-preparation": "这里将是您的转账地址。",
  "c-exchange-reqItem-security-check": "请等待所有准备和安全检查完成。",
  "c-exchange-reqItem-waiting-for-transaction": "平台正在等待您的交易。",
  "c-exchange-reqItem-transfer-here": "在此转移资金",
  "c-exchange-reqItem-wallet": "钱包",
  "c-exchange-reqItem-payment-option": "付款选项",
  "c-exchange-reqItem-card-number": "卡号",
  "c-exchange-reqItem-description": "描述",
  "c-exchange-reqItem-balance": "余额",
  "c-exchange-reqItem-payment-instructions":
    "付款完成后，请在聊天中分享付款发票的图片（或截图）",
  "c-exchange-reqItem-verification-required": "需要身份验证",
  "c-exchange-reqItem-upload-photo-instructions":
    "要继续您的交换请求，您需要上传一张您持有信用卡的照片。请确保以下内容：",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "持卡人的姓名和卡号的所有数字都清晰可见。",
  "c-exchange-reqItem-photo-instruction-face": "您的脸部在照片中清晰可见。",
  "c-exchange-reqItem-click-confirm-documents":
    "上传照片后，点击上方的'确认文件'按钮，并等待我们的主持人验证您的信息。",
  "c-exchange-reqItem-verification-in-progress": "验证进行中",
  "c-exchange-reqItem-moderator-review-in-progress":
    "感谢您上传照片。请等待我们的主持人审核并验证您的信息。此过程可能需要几分钟。感谢您的耐心等待。",
  "c-exchange-reqItem-select-payment-option-description":
    "从下面的列表中选择一个付款选项。选择的选项将作为客户的付款方式显示。",
  "c-exchange-reqItem-proceed-with-selected-option": "继续选择的选项",
};
