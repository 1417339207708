export default {
  "c-exchange-reqItem-cancelling-request":
    "Annulation de votre demande d'échange",
  "c-exchange-reqItem-cancel-request-confirm":
    "Êtes-vous sûr de vouloir annuler la demande ?",
  "c-exchange-reqItem-confirming-documents": "Confirmation de vos documents",
  "c-exchange-reqItem-upload-documents-instructions":
    "Assurez-vous que les images de votre document sont de haute résolution et que tous les chiffres sont clairement visibles",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Voulez-vous procéder à la confirmation des documents ?",
  "c-exchange-reqItem-select-payment-option":
    "Sélectionnez une option de paiement",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Voulez-vous continuer avec l'option de paiement sélectionnée ?",
  "c-exchange-reqItem-confirming-payment": "Confirmation du paiement",
  "c-exchange-reqItem-confirm-payment":
    "Voulez-vous confirmer votre paiement ?",
  "c-exchange-reqItem-completing-request": "Finalisation de la demande",
  "c-exchange-reqItem-provide-txid":
    "Pour compléter la demande, vous devez fournir l'identifiant de transaction de votre transfert",
  "c-exchange-reqItem-enter-txid-error": "Veuillez entrer txId ici",
  "c-exchange-reqItem-enter-txid-placeholder": "Entrez txId ici",
  "c-exchange-reqItem-verifying-card":
    "Vérification de la carte de l'utilisateur",
  "c-exchange-reqItem-moderator-verifying": "Le modérateur vérifie les données",
  "c-exchange-reqItem-waiting-alm": "En attente des résultats ALM",
  "c-exchange-reqItem-preparing-crypto":
    "Préparation de l'adresse de la plateforme",
  "c-exchange-reqItem-preparing-fiat": "Préparation des coordonnées bancaires",
  "c-exchange-reqItem-sending-crypto": "Envoi de crypto",
  "c-exchange-reqItem-sending-fiat": "Envoi de fiat",
  "c-exchange-reqItem-waiting-payment":
    "En attente du paiement de l'utilisateur",
  "c-exchange-reqItem-completed": "Complété",
  "c-exchange-reqItem-failed": "Échoué",
  "c-exchange-reqItem-cancelled": "Annulé",
  "c-exchange-reqItem-cancel-exchange-request": "Annuler la demande d'échange",
  "c-exchange-reqItem-confirm-documents": "Confirmer les documents",
  "c-exchange-reqItem-documents-confirmed": "Documents confirmés",
  "c-exchange-reqItem-payment-sent": "Paiement envoyé",
  "c-exchange-reqItem-confirm-user-payment":
    "Confirmer le paiement de l'utilisateur",
  "c-exchange-reqItem-complete-request": "Compléter la demande",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Échange",
  "c-exchange-reqItem-crypto-address": "Adresse crypto de l'utilisateur",
  "c-exchange-reqItem-fiat-card-number":
    "Numéro de carte fiat de l'utilisateur",
  "c-exchange-reqItem-request-failed": "Demande échouée",
  "c-exchange-reqItem-alm-link": "Lien vers les résultats ALM",
  "c-exchange-reqItem-check-here": "vérifiez ici",
  "c-exchange-reqItem-request-cancelled": "Demande annulée",
  "c-exchange-reqItem-request-fulfilled": "Votre demande a été accomplie !",
  "c-exchange-reqItem-thank-you":
    "Merci de nous avoir choisis comme service d'échange préféré. Nous apprécions votre confiance en nous et nous réjouissons de vous servir à nouveau !",
  "c-exchange-reqItem-address-preparation":
    "Voici l'adresse pour votre transfert.",
  "c-exchange-reqItem-security-check":
    "Veuillez attendre que toutes les préparations et vérifications de sécurité soient terminées.",
  "c-exchange-reqItem-waiting-for-transaction":
    "La plateforme attend votre transaction.",
  "c-exchange-reqItem-transfer-here": "Transférez les fonds ici",
  "c-exchange-reqItem-wallet": "portefeuille",
  "c-exchange-reqItem-payment-option": "Option de paiement",
  "c-exchange-reqItem-card-number": "Numéro de carte",
  "c-exchange-reqItem-description": "Description",
  "c-exchange-reqItem-balance": "solde",
  "c-exchange-reqItem-payment-instructions":
    "Après avoir effectué le paiement, veuillez partager une image de la facture (ou une capture d'écran) de votre paiement dans le chat",
  "c-exchange-reqItem-verification-required": "Vérification d'identité requise",
  "c-exchange-reqItem-upload-photo-instructions":
    "Pour poursuivre votre demande d'échange, nous avons besoin d'une photo de vous tenant la carte de crédit que vous comptez utiliser. Veuillez vous assurer que :",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "Le nom du titulaire de la carte et tous les chiffres du numéro de carte sont visibles.",
  "c-exchange-reqItem-photo-instruction-face":
    "Votre visage est clairement visible sur la photo.",
  "c-exchange-reqItem-click-confirm-documents":
    "Une fois la photo téléchargée dans le chat, cliquez sur le bouton 'Confirmer les documents' ci-dessus et attendez que notre modérateur vérifie vos informations.",
  "c-exchange-reqItem-verification-in-progress": "Vérification en cours",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Merci d'avoir téléchargé votre photo. Veuillez patienter pendant que notre modérateur examine et vérifie vos informations. Ce processus peut prendre quelques minutes. Nous vous remercions pour votre patience.",
  "c-exchange-reqItem-select-payment-option-description":
    "Choisissez une option de paiement dans la liste ci-dessous. L'option sélectionnée sera affichée au client comme méthode de paiement pour transférer des fonds.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Procéder avec l'option sélectionnée",
};
