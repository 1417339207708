export default {
  "p-exchange-exchange": "Exchange",
  "p-exchange-for": "for",
  "p-exchange-fixed-rate":
    "The exchange rate will be fixed after the application is created",
  "p-exchange-current-rate": "Current exchange rate for this pair is:",
  "p-exchange-send": "Send",
  "p-exchange-amount-to-send": "Amount to send",
  "p-exchange-crypto": "Crypto",
  "p-exchange-fiat": "Fiat",
  "p-exchange-card-number-verification":
    "Your card number: (needs for verifying)",
  "p-exchange-card-tooltip":
    "Before we can accept your money, we need to verify your identity and check if the card is not on blacklists",
  "p-exchange-learn-more": "(learn more?)",
  "p-exchange-wallet-verification":
    "Your {currency} wallet: (needs for ALM check)",
  "p-exchange-wallet-tooltip":
    "The ALM check needs to verify cryptocurrency, SolidSwap guarantees that all crypto receiving and sending are 'clean'",
  "p-exchange-enter-wallet": "Enter wallet here",
  "p-exchange-receive": "Receive",
  "p-exchange-on": "on {name}",
  "p-exchange-amount-to-receive": "Amount to receive",
  "p-exchange-card-number": "Card number",
  "p-exchange-wallet-receive": "Wallet for receive",
  "p-exchange-email-required": "E-mail (required)",
  "p-exchange-terms-message": "I have read and accept the",
  "p-exchange-terms": "Terms of Use",
  "p-exchange-and": "and",
  "p-exchange-privacy": "Privacy Policy",
  "p-exchange-alm-message": "I have read and accept the",
  "p-exchange-captcha": "Captcha",
  "p-exchange-not-allowed": "Not allowed",
  "p-exchange-not-allowed-message":
    "Admins and moderators of SolidSwap are not allowed to create an exchange request",
  "p-exchange-proceed": "Proceed to exchange",
  "p-exchange-menu-create-request": "Create exchange request",
  "p-exchange-menu-requests": "Requests",
  "p-exchange-accept-terms": "Please accept the terms of use",
  "p-exchange-accept-alm": "Please read and accept the AML/CTF & KYC",
  "p-exchange-enter-captcha": "Please enter captcha",
};
