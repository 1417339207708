export default {
  "c-exchange-reqItem-cancelling-request":
    "Դուք չեղարկում եք փոխանակման հարցումը",
  "c-exchange-reqItem-cancel-request-confirm":
    "Վստա՞հ եք, որ ցանկանում եք չեղարկել հարցումը:",
  "c-exchange-reqItem-confirming-documents": "Փաստաթղթերի հաստատում",
  "c-exchange-reqItem-upload-documents-instructions":
    "Համոզվեք, որ ձեր փաստաթղթի պատկերը բարձր որակի է, և բոլոր թվերը հստակ տեսանելի են:",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Ցանկանո՞ւմ եք շարունակել փաստաթղթերի հաստատման գործընթացը:",
  "c-exchange-reqItem-select-payment-option": "Ընտրել վճարման տարբերակ",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Ցանկանո՞ւմ եք շարունակել ընտրված վճարման տարբերակով:",
  "c-exchange-reqItem-confirming-payment": "Վճարման հաստատում",
  "c-exchange-reqItem-confirm-payment":
    "Ցանկանո՞ւմ եք շարունակել ձեր վճարման հաստատումը:",
  "c-exchange-reqItem-completing-request": "Հարցման ավարտում",
  "c-exchange-reqItem-provide-txid":
    "Հարցումը ավարտելու համար անհրաժեշտ է մուտքագրել փոխանցման թարգմանության ID:",
  "c-exchange-reqItem-enter-txid-error": "Խնդրում ենք մուտքագրել txId այստեղ",
  "c-exchange-reqItem-enter-txid-placeholder": "Մուտքագրեք txId այստեղ",
  "c-exchange-reqItem-verifying-card": "Հաստատում ենք օգտատիրոջ քարտը",
  "c-exchange-reqItem-moderator-verifying": "Մոդերատորը ստուգում է տվյալները",
  "c-exchange-reqItem-waiting-alm": "Սպասում է ALM արդյունքների",
  "c-exchange-reqItem-preparing-crypto": "Պատրաստում ենք հարթակի հասցեն",
  "c-exchange-reqItem-preparing-fiat": "Պատրաստում ենք ֆիատ վճարման տվյալները",
  "c-exchange-reqItem-sending-crypto": "Կրիպտո ուղարկվում է",
  "c-exchange-reqItem-sending-fiat": "Ֆիատ ուղարկվում է",
  "c-exchange-reqItem-waiting-payment": "Սպասում ենք օգտատիրոջ վճարմանը",
  "c-exchange-reqItem-completed": "Ավարտված է",
  "c-exchange-reqItem-failed": "Ձախողվել է",
  "c-exchange-reqItem-cancelled": "Չեղարկված է",
  "c-exchange-reqItem-cancel-exchange-request": "Չեղարկել փոխանակման հարցումը",
  "c-exchange-reqItem-confirm-documents": "Հաստատել փաստաթղթերը",
  "c-exchange-reqItem-documents-confirmed": "Փաստաթղթերը հաստատված են",
  "c-exchange-reqItem-payment-sent": "Վճարումը ուղարկվել է",
  "c-exchange-reqItem-confirm-user-payment": "Հաստատել օգտատիրոջ վճարումը",
  "c-exchange-reqItem-complete-request": "Ավարտել հարցումը",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Փոխանակում",
  "c-exchange-reqItem-crypto-address": "Օգտատիրոջ կրիպտո հասցեն",
  "c-exchange-reqItem-fiat-card-number": "Օգտատիրոջ ֆիատ քարտի համարը",
  "c-exchange-reqItem-request-failed": "Հարցումը ձախողվել է",
  "c-exchange-reqItem-alm-link": "ALM արդյունքների հղումը",
  "c-exchange-reqItem-check-here": "ստուգեք այստեղ",
  "c-exchange-reqItem-request-cancelled": "Հարցումը չեղարկված է",
  "c-exchange-reqItem-request-fulfilled": "Ձեր հարցումը ավարտված է:",
  "c-exchange-reqItem-thank-you":
    "Շնորհակալություն մեզ ընտրելու համար՝ որպես ձեր նախընտրած փոխանակման ծառայություն: Մենք գնահատում ենք ձեր վստահությունը և սպասում ենք ձեզ կրկին ծառայություն մատուցելու:",
  "c-exchange-reqItem-address-preparation":
    "Այստեղ կլինի ձեր փոխանցման հասցեն:",
  "c-exchange-reqItem-security-check":
    "Խնդրում ենք սպասել, մինչ բոլոր նախապատրաստությունները և անվտանգության ստուգումները կավարտվեն:",
  "c-exchange-reqItem-waiting-for-transaction":
    "Հարթակը սպասում է ձեր փոխանակմանը:",
  "c-exchange-reqItem-transfer-here": "Փոխանցեք միջոցները այստեղ",
  "c-exchange-reqItem-wallet": "դրամապանակ",
  "c-exchange-reqItem-payment-option": "Վճարման տարբերակ",
  "c-exchange-reqItem-card-number": "Քարտի համարը",
  "c-exchange-reqItem-description": "Նկարագրություն",
  "c-exchange-reqItem-balance": "մնացորդ",
  "c-exchange-reqItem-payment-instructions":
    "Վճարումը կատարելուց հետո խնդրում ենք կիսվել ձեր վճարման հաշվի նկարի (կամ սքրինշոթի) հետ չաթում",
  "c-exchange-reqItem-verification-required":
    "Անձնական նույնականացման հաստատում է անհրաժեշտ",
  "c-exchange-reqItem-upload-photo-instructions":
    "Ձեր փոխանակման հարցումը շարունակելու համար անհրաժեշտ է ներբեռնել ձեր կրեդիտ քարտը պահած անձի նկարը: Խնդրում ենք ապահովել հետևյալը՝",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "Քարտի սեփականատիրոջ անունն ու քարտի բոլոր թվերը հստակ տեսանելի են:",
  "c-exchange-reqItem-photo-instruction-face":
    "Ձեր դեմքը հստակ տեսանելի է նկարում:",
  "c-exchange-reqItem-click-confirm-documents":
    "Ձեր նկարը չաթում ներբեռնելուց հետո, սեղմեք 'Հաստատել փաստաթղթերը' կոճակը վերևում և սպասեք մեր մոդերատորի ստուգմանը:",
  "c-exchange-reqItem-verification-in-progress": "Ստուգումն ընթանում է",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Շնորհակալություն ձեր նկարը ներբեռնելու համար: Խնդրում ենք սպասել, մինչ մեր մոդերատորը ստուգում է ձեր տվյալները: Այս գործընթացը կարող է տևել մի քանի րոպե: Շնորհակալություն ձեր համբերության համար:",
  "c-exchange-reqItem-select-payment-option-description":
    "Ընտրեք վճարման տարբերակը ցուցակից: Ընտրված տարբերակը կցուցադրվի հաճախորդին՝ որպես միջոցների փոխանցման վճարման մեթոդ:",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Շարունակել ընտրված տարբերակով",
};
