export default {
  "c-exchange-reqItem-cancelling-request":
    "Cancelando su solicitud de intercambio",
  "c-exchange-reqItem-cancel-request-confirm":
    "¿Está seguro de que desea cancelar la solicitud?",
  "c-exchange-reqItem-confirming-documents": "Confirmando sus documentos",
  "c-exchange-reqItem-upload-documents-instructions":
    "Asegúrese de que las imágenes de su documento tengan alta resolución y que todos los dígitos sean claramente visibles",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "¿Desea continuar con la confirmación de los documentos?",
  "c-exchange-reqItem-select-payment-option": "Seleccionar opción de pago",
  "c-exchange-reqItem-proceed-with-payment-option":
    "¿Desea continuar con la opción de pago seleccionada?",
  "c-exchange-reqItem-confirming-payment": "Confirmando el pago",
  "c-exchange-reqItem-confirm-payment":
    "¿Desea continuar con la confirmación de su pago?",
  "c-exchange-reqItem-completing-request": "Completando la solicitud",
  "c-exchange-reqItem-provide-txid":
    "Para completar la solicitud, debe proporcionar el ID de transacción de su transferencia",
  "c-exchange-reqItem-enter-txid-error": "Por favor ingrese el txId aquí",
  "c-exchange-reqItem-enter-txid-placeholder": "Ingrese txId aquí",
  "c-exchange-reqItem-verifying-card": "Verificando la tarjeta del usuario",
  "c-exchange-reqItem-moderator-verifying":
    "El moderador está verificando los datos",
  "c-exchange-reqItem-waiting-alm": "Esperando resultados de ALM",
  "c-exchange-reqItem-preparing-crypto":
    "Preparando la dirección de la plataforma",
  "c-exchange-reqItem-preparing-fiat": "Preparando los datos del fiat",
  "c-exchange-reqItem-sending-crypto": "Enviando cripto",
  "c-exchange-reqItem-sending-fiat": "Enviando fiat",
  "c-exchange-reqItem-waiting-payment": "Esperando el pago del usuario",
  "c-exchange-reqItem-completed": "Completado",
  "c-exchange-reqItem-failed": "Fallido",
  "c-exchange-reqItem-cancelled": "Cancelado",
  "c-exchange-reqItem-cancel-exchange-request":
    "Cancelar la solicitud de intercambio",
  "c-exchange-reqItem-confirm-documents": "Confirmar documentos",
  "c-exchange-reqItem-documents-confirmed": "Documentos confirmados",
  "c-exchange-reqItem-payment-sent": "El pago ha sido enviado",
  "c-exchange-reqItem-confirm-user-payment": "Confirmar el pago del usuario",
  "c-exchange-reqItem-complete-request": "Completar solicitud",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Intercambiando",
  "c-exchange-reqItem-crypto-address": "Dirección de cripto del usuario",
  "c-exchange-reqItem-fiat-card-number": "Número de tarjeta fiat del usuario",
  "c-exchange-reqItem-request-failed": "La solicitud ha fallado",
  "c-exchange-reqItem-alm-link": "Enlace a los resultados de ALM",
  "c-exchange-reqItem-check-here": "verificar aquí",
  "c-exchange-reqItem-request-cancelled": "Solicitud cancelada",
  "c-exchange-reqItem-request-fulfilled": "¡Su solicitud ha sido completada!",
  "c-exchange-reqItem-thank-you":
    "Gracias por elegirnos como su servicio de intercambio preferido. Apreciamos su confianza en nosotros y esperamos poder atenderle nuevamente.",
  "c-exchange-reqItem-address-preparation":
    "Aquí estará la dirección para su transferencia.",
  "c-exchange-reqItem-security-check":
    "Espere hasta que se completen todas las preparaciones y verificaciones de seguridad.",
  "c-exchange-reqItem-waiting-for-transaction":
    "La plataforma está esperando su transacción.",
  "c-exchange-reqItem-transfer-here": "Transfiera los fondos aquí",
  "c-exchange-reqItem-wallet": "billetera",
  "c-exchange-reqItem-payment-option": "Opción de pago",
  "c-exchange-reqItem-card-number": "Número de tarjeta",
  "c-exchange-reqItem-description": "Descripción",
  "c-exchange-reqItem-balance": "saldo",
  "c-exchange-reqItem-payment-instructions":
    "Después de realizar el pago, por favor comparta una imagen de la factura (o captura de pantalla) de su pago en el chat",
  "c-exchange-reqItem-verification-required":
    "Verificación de identidad requerida",
  "c-exchange-reqItem-upload-photo-instructions":
    "Para continuar con su solicitud de intercambio, necesitamos que cargue una foto de usted sosteniendo la tarjeta de crédito que planea usar. Asegúrese de lo siguiente:",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "El nombre del titular de la tarjeta y todos los dígitos del número de tarjeta son claramente visibles.",
  "c-exchange-reqItem-photo-instruction-face":
    "Su rostro es claramente visible en la foto.",
  "c-exchange-reqItem-click-confirm-documents":
    "Una vez que haya subido la foto en el chat, haga clic en el botón 'Confirmar documentos' de arriba y espere a que nuestro moderador verifique su información.",
  "c-exchange-reqItem-verification-in-progress": "Verificación en progreso",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Gracias por subir su foto. Espere mientras nuestro moderador revisa y verifica su información. Este proceso puede tardar unos minutos. Agradecemos su paciencia.",
  "c-exchange-reqItem-select-payment-option-description":
    "Elija una opción de pago de la lista a continuación. La opción seleccionada se mostrará al cliente como el método de pago para transferir fondos.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Continuar con la opción seleccionada",
};
