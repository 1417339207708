import blog from "./components/blog";
import chat from "./components/chat";
import feedback from "./components/feedback";
import footer from "./components/footer";
import header from "./components/header";
import home from "./components/home";
import notifications from "./components/notifications";
import offersEditform from "./components/offers-editform";
import offersTable from "./components/offers-table";
import offersTraderInfo from "./components/offers-traderInfo";
import orderCreate from "./components/order-create";
import orderSteps from "./components/order-steps";
import paymentOption from "./components/payment-option";
import transaction from "./components/transaction";
import user from "./components/user";
import withdrawal from "./components/withdrawal";
import widget from "./components/widget";
import exchangeLI from "./components/exchange-listItem";
import exchangeS from "./components/exchange-steps";
import exchangeItem from "./components/exchange-req-item";

import layoutDefault from "./layouts/default";

import useNotifications from "./stores/useNotifications";

import blogP from "./pages/blog";
import confirmEmail from "./pages/confirm-email";
import profile from "./pages/profile";
import tradeAction from "./pages/trade-action";
import tradeMyOffers from "./pages/trade-my-offers";
import tradeOrders from "./pages/trade-orders";
import tradeProfile from "./pages/trade-profile";
import userP from "./pages/user";
import about from "./pages/about";
import beginnerGuide from "./pages/beginners-guide";
import faq from "./pages/faq";
import help from "./pages/help";
import index from "./pages/index";
import login from "./pages/login";
import registration from "./pages/registration";
import news from "./pages/news";
import resetPassword from "./pages/resetPassword";
import trade from "./pages/trade";
import tos from "./pages/tos";
import privicyPolicy from "./pages/privicy-policy";
import supportChat from "./pages/support-chat";
import exchange from "./pages/exchange";
import exchangeRequests from "./pages/exchange-requests";
import exchangeRequestID from "./pages/exchange-requestID";

export default {
  ...exchangeItem,
  ...exchangeS,
  ...exchangeLI,
  ...blog,
  ...chat,
  ...feedback,
  ...footer,
  ...header,
  ...home,
  ...notifications,
  ...offersEditform,
  ...offersTable,
  ...offersTraderInfo,
  ...orderCreate,
  ...orderSteps,
  ...paymentOption,
  ...transaction,
  ...user,
  ...withdrawal,
  ...widget,

  ...layoutDefault,

  ...useNotifications,

  ...blogP,
  ...confirmEmail,
  ...profile,
  ...trade,
  ...tradeAction,
  ...tradeMyOffers,
  ...tradeOrders,
  ...tradeProfile,
  ...userP,
  ...about,
  ...beginnerGuide,
  ...faq,
  ...help,
  ...index,
  ...login,
  ...registration,
  ...news,
  ...resetPassword,
  ...tos,
  ...privicyPolicy,
  ...supportChat,
  ...exchange,
  ...exchangeRequests,
  ...exchangeRequestID,
};
