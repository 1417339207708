export default {
  "p-exchange-exchange": "Wechsel",
  "p-exchange-for": "für",
  "p-exchange-fixed-rate":
    "Der Wechselkurs wird nach Erstellung des Antrags festgelegt",
  "p-exchange-current-rate": "Aktueller Wechselkurs für dieses Paar ist:",
  "p-exchange-send": "Senden",
  "p-exchange-amount-to-send": "Zu sendender Betrag",
  "p-exchange-crypto": "Krypto",
  "p-exchange-fiat": "Fiat",
  "p-exchange-card-number-verification":
    "Ihre Kartennummer: (für Verifizierung erforderlich)",
  "p-exchange-card-tooltip":
    "Bevor wir Ihr Geld akzeptieren können, müssen wir Ihre Identität überprüfen und sicherstellen, dass die Karte nicht auf schwarzen Listen steht",
  "p-exchange-learn-more": "(mehr erfahren?)",
  "p-exchange-wallet-verification":
    "Ihre {currency} Wallet: (für ALM-Überprüfung erforderlich)",
  "p-exchange-wallet-tooltip":
    "Die ALM-Überprüfung muss Kryptowährungen verifizieren, SolidSwap garantiert, dass alle Krypto-Transaktionen „sauber“ sind",
  "p-exchange-enter-wallet": "Geben Sie hier die Wallet ein",
  "p-exchange-receive": "Empfangen",
  "p-exchange-on": "auf {name}",
  "p-exchange-amount-to-receive": "Zu erhaltender Betrag",
  "p-exchange-card-number": "Kartennummer",
  "p-exchange-wallet-receive": "Wallet zum Empfangen",
  "p-exchange-email-required": "E-Mail (erforderlich)",
  "p-exchange-terms-message": "Ich habe gelesen und akzeptiere die",
  "p-exchange-terms": "Nutzungsbedingungen",
  "p-exchange-and": "und",
  "p-exchange-privacy": "Datenschutzrichtlinie",
  "p-exchange-alm-message": "Ich habe gelesen und akzeptiere die",
  "p-exchange-captcha": "Captcha",
  "p-exchange-not-allowed": "Nicht erlaubt",
  "p-exchange-not-allowed-message":
    "Admins und Moderatoren von SolidSwap dürfen keine Wechselanfragen erstellen",
  "p-exchange-proceed": "Weiter zum Wechsel",
  "p-exchange-menu-create-request": "Tauschanfrage erstellen",
  "p-exchange-menu-requests": "Anfragen",
  "p-exchange-accept-terms": "Bitte akzeptieren Sie die Nutzungsbedingungen",
  "p-exchange-accept-alm": "Bitte lesen und akzeptieren Sie AML/CTF und KYC",
  "p-exchange-enter-captcha": "Bitte geben Sie das Captcha ein",
};
