export default {
  "p-exchange-exchange": "Intercambio",
  "p-exchange-for": "para",
  "p-exchange-fixed-rate":
    "El tipo de cambio se fijará después de crear la solicitud",
  "p-exchange-current-rate": "El tipo de cambio actual para este par es:",
  "p-exchange-send": "Enviar",
  "p-exchange-amount-to-send": "Monto a enviar",
  "p-exchange-crypto": "Cripto",
  "p-exchange-fiat": "Fiat",
  "p-exchange-card-number-verification":
    "Su número de tarjeta: (necesario para verificar)",
  "p-exchange-card-tooltip":
    "Antes de aceptar su dinero, necesitamos verificar su identidad y comprobar si la tarjeta está en listas negras",
  "p-exchange-learn-more": "(¿saber más?)",
  "p-exchange-wallet-verification":
    "Su billetera {currency}: (necesaria para la verificación ALM)",
  "p-exchange-wallet-tooltip":
    "La verificación ALM necesita comprobar criptomonedas, SolidSwap garantiza que todas las transacciones de criptomonedas son 'limpias'",
  "p-exchange-enter-wallet": "Ingrese la billetera aquí",
  "p-exchange-receive": "Recibir",
  "p-exchange-on": "en {name}",
  "p-exchange-amount-to-receive": "Monto a recibir",
  "p-exchange-card-number": "Número de tarjeta",
  "p-exchange-wallet-receive": "Billetera para recibir",
  "p-exchange-email-required": "Correo electrónico (obligatorio)",
  "p-exchange-terms-message": "He leído y acepto los",
  "p-exchange-terms": "Términos de uso",
  "p-exchange-and": "y",
  "p-exchange-privacy": "Política de privacidad",
  "p-exchange-alm-message": "He leído y acepto los",
  "p-exchange-captcha": "Captcha",
  "p-exchange-not-allowed": "No permitido",
  "p-exchange-not-allowed-message":
    "Los administradores y moderadores de SolidSwap no están autorizados a crear solicitudes de intercambio",
  "p-exchange-proceed": "Proceder al intercambio",
  "p-exchange-menu-create-request": "Crear solicitud de intercambio",
  "p-exchange-menu-requests": "Solicitudes",
  "p-exchange-accept-terms": "Por favor acepte los términos de uso",
  "p-exchange-accept-alm": "Por favor lea y acepte AML/CTF y KYC",
  "p-exchange-enter-captcha": "Por favor ingrese el captcha",
};
