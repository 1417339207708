export default {
  "c-exchange-reqItem-cancelling-request": "Отмена вашего запроса на обмен",
  "c-exchange-reqItem-cancel-request-confirm":
    "Вы уверены, что хотите отменить запрос?",
  "c-exchange-reqItem-confirming-documents": "Подтверждение ваших документов",
  "c-exchange-reqItem-upload-documents-instructions":
    "Убедитесь, что изображения вашего документа имеют высокое разрешение, и все цифры четко видны",
  "c-exchange-reqItem-confirm-documents-confirmation":
    "Вы хотите продолжить подтверждение документов?",
  "c-exchange-reqItem-select-payment-option": "Выберите способ оплаты",
  "c-exchange-reqItem-proceed-with-payment-option":
    "Вы хотите продолжить с выбранным способом оплаты?",
  "c-exchange-reqItem-confirming-payment": "Подтверждение оплаты",
  "c-exchange-reqItem-confirm-payment": "Вы хотите подтвердить свою оплату?",
  "c-exchange-reqItem-completing-request": "Завершение запроса",
  "c-exchange-reqItem-provide-txid":
    "Для завершения запроса вам необходимо предоставить идентификатор транзакции вашего перевода",
  "c-exchange-reqItem-enter-txid-error": "Пожалуйста, введите txId здесь",
  "c-exchange-reqItem-enter-txid-placeholder": "Введите txId здесь",
  "c-exchange-reqItem-verifying-card": "Проверка карты пользователя",
  "c-exchange-reqItem-moderator-verifying": "Модератор проверяет данные",
  "c-exchange-reqItem-waiting-alm": "Ожидание результатов ALM",
  "c-exchange-reqItem-preparing-crypto": "Подготовка адреса платформы",
  "c-exchange-reqItem-preparing-fiat": "Подготовка реквизитов для фиата",
  "c-exchange-reqItem-sending-crypto": "Отправка криптовалюты",
  "c-exchange-reqItem-sending-fiat": "Отправка фиата",
  "c-exchange-reqItem-waiting-payment": "Ожидание оплаты от пользователя",
  "c-exchange-reqItem-completed": "Завершено",
  "c-exchange-reqItem-failed": "Неудачно",
  "c-exchange-reqItem-cancelled": "Отменено",
  "c-exchange-reqItem-cancel-exchange-request": "Отменить запрос на обмен",
  "c-exchange-reqItem-confirm-documents": "Подтвердить документы",
  "c-exchange-reqItem-documents-confirmed": "Документы подтверждены",
  "c-exchange-reqItem-payment-sent": "Оплата отправлена",
  "c-exchange-reqItem-confirm-user-payment": "Подтвердить оплату пользователя",
  "c-exchange-reqItem-complete-request": "Завершить запрос",
  "c-exchange-reqItem-id": "ID",
  "c-exchange-reqItem-exchanging": "Обмен",
  "c-exchange-reqItem-crypto-address": "Криптовалютный адрес пользователя",
  "c-exchange-reqItem-fiat-card-number": "Номер фиатной карты пользователя",
  "c-exchange-reqItem-request-failed": "Запрос не выполнен",
  "c-exchange-reqItem-alm-link": "Ссылка на результаты ALM",
  "c-exchange-reqItem-check-here": "проверить здесь",
  "c-exchange-reqItem-request-cancelled": "Запрос отменен",
  "c-exchange-reqItem-request-fulfilled": "Ваш запрос выполнен!",
  "c-exchange-reqItem-thank-you":
    "Спасибо, что выбрали нас в качестве предпочтительного обменного сервиса. Мы очень ценим ваше доверие к нам и с нетерпением ждем возможности снова вам служить!",
  "c-exchange-reqItem-address-preparation":
    "Здесь будет адрес для вашего перевода.",
  "c-exchange-reqItem-security-check":
    "Пожалуйста, подождите, пока все приготовления и проверки безопасности будут завершены.",
  "c-exchange-reqItem-waiting-for-transaction":
    "Платформа ожидает вашу транзакцию.",
  "c-exchange-reqItem-transfer-here": "Переведите средства сюда",
  "c-exchange-reqItem-wallet": "кошелек",
  "c-exchange-reqItem-payment-option": "Способ оплаты",
  "c-exchange-reqItem-card-number": "Номер карты",
  "c-exchange-reqItem-description": "Описание",
  "c-exchange-reqItem-balance": "баланс",
  "c-exchange-reqItem-payment-instructions":
    "После совершения оплаты, пожалуйста, поделитесь изображением квитанции (или снимком экрана) вашего платежа в чате",
  "c-exchange-reqItem-verification-required": "Требуется проверка личности",
  "c-exchange-reqItem-upload-photo-instructions":
    "Чтобы продолжить ваш запрос на обмен, нам нужно, чтобы вы загрузили фотографию, на которой вы держите кредитную карту, которую планируете использовать. Пожалуйста, убедитесь, что:",
  "c-exchange-reqItem-photo-instruction-cardholder":
    "Имя владельца карты и все цифры номера карты четко видны.",
  "c-exchange-reqItem-photo-instruction-face":
    "Ваше лицо на фотографии четко видно.",
  "c-exchange-reqItem-click-confirm-documents":
    "После того как вы загрузите фото в чат, нажмите на кнопку 'Подтвердить документы' выше и дождитесь проверки информации нашим модератором.",
  "c-exchange-reqItem-verification-in-progress": "Проверка в процессе",
  "c-exchange-reqItem-moderator-review-in-progress":
    "Спасибо за загрузку фотографии. Пожалуйста, подождите, пока наш модератор проверит и подтвердит вашу информацию. Этот процесс может занять несколько минут. Спасибо за ваше терпение.",
  "c-exchange-reqItem-select-payment-option-description":
    "Выберите способ оплаты из списка ниже. Выбранный вариант будет отображаться клиенту как способ оплаты для перевода средств.",
  "c-exchange-reqItem-proceed-with-selected-option":
    "Продолжить с выбранным вариантом",
};
